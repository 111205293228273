.Tableau {
  width: 100%;
  height: 100%;

  &__div {
    .wrapper {
      width: 100%;
      }

  }
  @media screen and (max-width: 640px) {
  }

  .StandardMenu {
    z-index: 1;
  }
}
